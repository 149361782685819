<template>
  <DefaultLayout>
    <template v-slot:toolbar>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>{{ $route.query.exam_type_title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$refs.myRightBar.show()">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>
    <v-container class="mb-4" fluid>
      <NilaiList ref="refNilaiList"></NilaiList>
    </v-container>
    <RightBar ref="myRightBar" title="Filter">
      <v-text-field
        clearable
        v-model="search"
        placeholder="Cari"
        label="Cari"
      ></v-text-field>
      <v-select
        v-model="selectedAcademicYear"
        :items="academicYearList"
        item-value="id"
        item-text="year_text"
        label="Tahun Akademik"
        return-object
        @change="getSubjectList"
      ></v-select>
      <v-select
        v-model="selectedGrade"
        :items="gradeList"
        label="Kelas"
        @change="getSubjectList"
      ></v-select>
      <v-select
        v-model="selectedSubject"
        :items="subjectList"
        item-value="id"
        item-text="name"
        label="Mata Pelajaran"
        return-object
      ></v-select>
      <v-select
        v-model="selectedExamType"
        :items="examTypeList"
        item-value="exam_type"
        item-text="title"
        label="Tipe"
        return-object
      ></v-select>

      <v-row class="mt-2">
        <v-col>
          <v-btn class="mr-1" color="primary" @click="setFilter">Filter</v-btn>
          <v-btn @click="resetFilter">Reset</v-btn>
        </v-col>
      </v-row>
    </RightBar>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import RightBar from "@/layouts/RightBar.vue";
import NilaiList from "@/components/NilaiList.vue";

export default {
  name: "ScoresDetail",
  components: { DefaultLayout, NilaiList, RightBar },
  data: () => ({
    examTypeList: [
      { title: "-- Semua --", exam_type: "", },
      { title: "Ulangan", exam_type: "UH", },
      { title: "Tugas", exam_type: "T", },
      { title: "PTS", exam_type: "PTS", },
      { title: "PAS", exam_type: "PAS", },
      { title: "PAT", exam_type: "PAT", },
    ],
    filterExamTypeTitle: "Pencarian",
    search: null,
    academicYearList: [],
    gradeList: [7, 8, 9],
    subjectList: [],
    selectedAcademicYear: {},
    selectedExamType: {},
    selectedGrade: null,
    selectedSubject: {},
    params: {},
  }),
  methods: {
    setFilter() {
      try {
        this.params = {
          search: this.search,
          exam_type: this.selectedExamType.exam_type,
          exam_type_title: this.filterExamTypeTitle,
          subjects_id: this.selectedSubject.id,
          grade: this.selectedGrade,
          academic_year_id: this.selectedAcademicYear.id,
        };

        this.$router
          .push({ name: "ScoresDetail", query: this.params })
          .catch(() => {});

        this.$refs.myRightBar.hide();
      } catch (error) {
        console.log(error);
      }

      this.$refs.myRightBar.hide();
    },

    resetFilter() {
      try {
        this.search = "";
        this.selectedExamType = {};
        this.selectedSubject = {};
        this.selectedGrade = null;
        this.selectedAcademicYear = {};

        this.setFilter();
      } catch (error) {
        console.log(error);
      }
      this.$refs.myRightBar.hide();
    },

    getAcademicYear() {
      this.$axios.get("nilai/academicyear").then((res) => {
        if (res.data.status == "success") {
          this.academicYearList = res.data.data;
        } else {
          this.academicYearList = [];
        }
      });
    },

    getSubjectList() {
      var data = {
        params: {
          grade: this.selectedGrade,
          academic_year_id: this.selectedAcademicYear.id,
        },
      };
      this.$axios.get("nilai/subject", data).then((res) => {
        if (res.data.status == "success") {
          this.subjectList = res.data.data;
        } else {
          this.subjectList = [];
        }
      });
    },
  },

  mounted() {
    this.gradeList                         = [7, 8, 9]
    this.examTypeList                      = [
      { title: "-- Semua --", exam_type: "", },
      { title: "Ulangan", exam_type: "UH", },
      { title: "Tugas", exam_type: "T", },
      { title: "PTS", exam_type: "PTS", },
      { title: "PAS", exam_type: "PAS", },
      { title: "PAT", exam_type: "PAT", },
    ]
    this.search                            = this.$route.query.search;
    this.filterExamTypeTitle               = this.$route.query.exam_type_title;
    this.selectedGrade                     = this.$route.query.grade;
    this.selectedAcademicYear.id           = this.$route.query.academic_year_id;
    this.selectedSubject.id                = this.$route.query.subjects_id;
    this.selectedExamType.exam_type        = this.$route.query.exam_type;

    this.getAcademicYear();
    this.getSubjectList();

    this.setPageTitle("List Nilai", "mdi-list");

    this.$refs.refNilaiList.getList();
  },
};
</script>