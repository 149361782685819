<template>
  <DefaultLayout>
    <template slot="toolbar">
      <slot name="appDrawer"></slot>
      <v-toolbar-title class="pl-1">
        <v-icon v-if="$store.state.pageIcon" class="pb-1 pr-2">{{
          $store.state.pageIcon
        }}</v-icon>
        <span>{{ $store.state.pageTitle }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$refs.myRightBar.show()">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>

    <v-container>
      <div class="text-h6 ml-4 mb-2">
        <v-icon color="blue">{{ $store.state.pageIcon }}</v-icon>
        {{ $store.state.pageTitle }}
      </div>
      <v-divider class="mb-4"></v-divider>

      <h3>Kategori</h3>
      <div class="my-4">
        <v-row>
          <v-col cols="3" v-for="(et, i) in examTypeList2" :key="i">
            <div class="text-center" v-if="et.exam_type != ''">
              <v-btn class="mx-auto" fab link :to="et.to">
                <v-icon dark> {{ et.icon }} </v-icon>
              </v-btn>
              <p class="text-center mx-auto">{{ et.title }}</p>
            </div>
          </v-col>
        </v-row>
      </div>

      <NilaiLates ref="refNilaiLates"></NilaiLates>
    </v-container>
    <RightBar ref="myRightBar" title="Filter">
      <v-text-field
        clearable
        v-model="search"
        placeholder="Cari"
        label="Cari"
      ></v-text-field>
      <v-select
        v-model="selectedAcademicYear"
        :items="academicYearList"
        item-value="id"
        item-text="year_text"
        label="Tahun Akademik"
        return-object
        @change="getSubjectList"
      ></v-select>
      <v-select
        v-model="selectedGrade"
        :items="gradeList"
        label="Kelas"
        @change="getSubjectList"
      ></v-select>
      <v-select
        v-model="selectedSubject"
        :items="subjectList"
        item-value="id"
        item-text="name"
        label="Mata Pelajaran"
        return-object
      ></v-select>
      <v-select
        v-model="selectedExamType"
        :items="examTypeList"
        item-value="exam_type"
        item-text="title"
        label="Tipe"
        return-object
      ></v-select>

      <v-row class="mt-2">
        <v-col>
          <v-btn class="mr-1" color="primary" @click="setFilter">Filter</v-btn>
          <v-btn @click="resetFilter">Reset</v-btn>
        </v-col>
      </v-row>
    </RightBar>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import RightBar from "@/layouts/RightBar.vue";
import NilaiLates from "@/components/NilaiLates.vue";

export default {
  name: "Scores",

  data: () => ({
    examTypeList: [
      {
        title: "-- Semua --",
        exam_type: "",
      },
      {
        title: "Ulangan",
        exam_type: "UH",
      },
      {
        title: "Tugas",
        exam_type: "T",
      },
      {
        title: "PTS",
        exam_type: "PTS",
      },
      {
        title: "PAS",
        icon: "mdi-book",
        exam_type: "PAS",
      },
      {
        title: "PAT",
        icon: "mdi-book",
        exam_type: "PAT",
      },
    ],

    examTypeList2: [
      {
        title: "Ulangan",
        icon: "mdi-refresh",
        exam_type: "UH",
        to: {
          name: "ScoresDetail",
          query: { exam_type: "UH", exam_type_title: "Ulangan" },
        },
      },
      {
        title: "Tugas",
        icon: "mdi-pencil",
        exam_type: "T",
        to: {
          name: "ScoresDetail",
          query: { exam_type: "T", exam_type_title: "Tugas" },
        },
      },
      {
        title: "PTS",
        icon: "mdi-book",
        exam_type: "PTS",
        to: {
          name: "ScoresDetail",
          query: { exam_type: "PTS", exam_type_title: "PTS" },
        },
      },
      {
        title: "PAS",
        icon: "mdi-book",
        to: {
          name: "ScoresDetail",
          query: { exam_type: "PAS", exam_type_title: "PAS" },
        },
      },
      {
        title: "PAT",
        icon: "mdi-book",
        exam_type: "PAT",
        to: {
          name: "ScoresDetail",
          query: { exam_type: "PAT", exam_type_title: "PAT" },
        },
      },
    ],
    filterExamTypeTitle: 'Pencarian',
    search: null,
    academicYearList: [],
    gradeList: [7, 8, 9],
    subjectList: [],
    selectedAcademicYear: {},
    selectedExamType: {},
    selectedGrade: null,
    selectedSubject: {},
    params: {},
  }),

  components: {
    DefaultLayout,
    NilaiLates,
    RightBar,
  },

  methods: {
    setFilter() {
      try {
        this.params = {
          search: this.search,
          exam_type: this.selectedExamType.exam_type,
          exam_type_title: this.filterExamTypeTitle,
          subjects_id: this.selectedSubject.id,
          grade: this.selectedGrade,
          academic_year_id: this.selectedAcademicYear.id,
        };

        this.$router.push({ name: "ScoresDetail", query: this.params }).catch(()=>{});

        this.$refs.myRightBar.hide();
      } catch (error) {
        console.log(error);
      }

      this.$refs.myRightBar.hide();
    },
    resetFilter() {
      try {
        this.filterCategory = {
          id: null,
          category: "",
        };
        this.search = "";
        this.selectedExamType.exam_type = ""
        this.filterExamTypeTitle = null
        this.selectedSubject.id = null
        this.selectedGrade = null
        this.selectedAcademicYear.id = null
      } catch (error) {
        console.log(error);
      }
      this.$refs.myRightBar.hide();
    },


    async getAcademicYear(){
      await this.$axios.get("nilai/academicyear").then((res) => {
        if (res.data.status == "success") {
          this.academicYearList = res.data.data;
        } else {
          this.academicYearList = [];
        }
      });
    },

    async getSubjectList(){
      var data = {
        params: {
          grade: this.selectedGrade,
          academic_year_id: this.selectedAcademicYear.id,
        }
      }
      await this.$axios.get("nilai/subject", data).then((res) => {
        if (res.data.status == "success") {
          this.subjectList = res.data.data;
        } else {
          this.subjectList = [];
        }
      });
    },
  },

  mounted() {
    this.getAcademicYear();
    this.getSubjectList();
    this.setPageTitle("Nilai", "mdi-book-open");
  },
};
</script>
